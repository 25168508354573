<template>
    <ContentWrapper>
        <breadcrumb name="Sell Harvest"/>
        <div class="container animated-fast fadeInUp">

            <div class="card card-default">
                <div class="card-header bg-gray-lighter text-bold">

                </div>
                <div class="card-body">

                    <ul>
                        <li></li>
                    </ul>

                    <div class="custom-control custom-checkbox text-right mb-3">
                        <input type="checkbox" class="custom-control-input" v-model="conditionsConfirmed" name="agreements" id="conditionsConfirmed">
                        <label class="custom-control-label" for="conditionsConfirmed">
                            {{$t('growing.view.action.action.sell_harvest.agree.agree')}} <a class="ml-1" href="https://cannergrow.com/terms/sell-contract" tabIndex="-1" target="_blank">{{$t('growing.view.action.action.sell_harvest.agree.terms')}}</a>
                        </label>
                    </div>

                    <b-btn>{{$t('growing.view.action.action.sell_harvest.search')}}</b-btn>

                </div>
            </div>

        </div>
    </ContentWrapper>
</template>
<style>

</style>
<script>

    export default {
        components: {},
        data() {
            return {
                harvest: null,
                loading: true
            }
        },
        mounted() {

            this.$api.get('growing/harvest').then(response => {
                this.harvest = response.data;
                this.loading = false;
            });
        },
        methods: {

        }
    }
</script>
